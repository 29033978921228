import Other, {Head as OtherHead} from "../voor-wie";
import {graphql} from "gatsby";

export default Other
export const Head = OtherHead

// noinspection GraphQLUnresolvedReference
export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "voor-wie"}}) {
            html
            frontmatter {
                title
                slug
            }
        }
    }
`